import { Script } from 'gatsby';
import React from 'react';

const KatinaChat = () => (
  <>
    <Script
      src={`${process.env.GATSBY_OWNUP_KATINA_BOT_WIDGET}`}
      onLoad={() => {
        if (window?.ownUpWidgets) {
          window.ownUpWidgets['widgets/katina-bot-landing-page/injectable'].inject(
            '#own-up-katina-top-of-funnel'
          );
        }
      }}
    ></Script>
    <div id="own-up-katina-top-of-funnel"></div>
  </>
);

export default KatinaChat;
